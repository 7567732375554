/* eslint-disable react-hooks/exhaustive-deps */
import ConfirmDialog from "main/components/ConfirmDialog/ConfirmDialog";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import Header from "../main/components/Header";
import SideNav from "../main/components/SideNav";
import {
  getFallbackUrl,
  isAuthenticated,
  logoutLocal,
  permissions,
  setHeader,
  showToast,
} from "../main/shared/helpers";
import { SET_SEARCH_LABEL, SHOW_GOBACK, SHOW_SEARCH } from "../store/actions";
import routes, { authRoles } from "./route-config";
import RouteCustom from "./route-custom";
import "./style.css";
import ReactGA from "react-ga";
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

const ToastContainer = React.lazy(() => import("../main/shared/toast"));
const ReactTooltip = React.lazy(() => import("react-tooltip"));

export const navigationRef = React.createRef(null);
export const reduxRef = React.createRef(null);
export const navigate = (path) => {
  if (navigationRef?.current?.history) {
    navigationRef.current.history.push(path);
  }
};
export const logoutFromInterceptor = (message) => {
  if (reduxRef.current) {
    message && showToast(message);
    logoutLocal(reduxRef.current.dispatch);
  }
};

let combinedRoutes = [];
routes.forEach((r) => {
  if (r.path === "/tools") {
    const shallowR = { ...r };
    const children = shallowR.childrens;
    delete shallowR.children;
    combinedRoutes.push(
      ...children.map((c) => ({ ...shallowR, ...c, path: r.path + c.to }))
    );
  } else {
    combinedRoutes.push(r);
  }
});
console.log("combinedRoutes", combinedRoutes);

const AppRouter = () => {
  const role = useSelector((state) => state.user.data.role);
  const accessibleRoutes = useSelector((state) => state.user.data.roles);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [fallbackUri, setFallbackUri] = useState("");
  const userInfo = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  reduxRef.current = { dispatch };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    setFallbackUri(
      `${appropriateFallbackUri(
        role,
        userInfo.first_name && userInfo.last_name,
        accessibleRoutes
      )}`
    );
    console.log("use effect role", role);
    let tempRoutes;
    if (role === undefined) {
      tempRoutes = routes.filter((route) => {
        return route.roles.length === 0 || route.isProtected === "both";
      });
    } else {
      if (
        role === authRoles.buAdmin ||
        role === authRoles.superAdmin ||
        role === authRoles.ultraAdmin ||
        role === authRoles.buInspector ||
        role === authRoles.buSubAdmin
      ) {
        tempRoutes = routes.filter((route) => {
          return route.isProtected === "both" || route.roles.includes(role);
        });
      } else if (role === authRoles.buSubAdmin) {
        const availablePaths = permissions.filter((pr) =>
          accessibleRoutes.some((ar) => ar.alias === pr.value)
        );
        tempRoutes = routes.filter((route) => {
          return (
            route.isProtected === "both" ||
            availablePaths.some((i) => route.path.startsWith(i.routePath)) ||
            ["/subadmin-profile", "/subscription-expired"].includes(route.path)
          );
        });
      }
    }
    setAvailableRoutes([...tempRoutes]);
  }, [role]);

  return (
    <Router ref={navigationRef}>
      <HeaderandSideNav />
      <Switch>
        {availableRoutes.map((route, index) => {
          if (route.hasChildren) {
            return route.childrens.map((child, i) => (
              <RouteCustom
                key={`route-${i}`}
                path={route.path + child.to}
                exact={route.exact}
                component={route.component}
                isProtected={route.isProtected}
                role={role}
              >
                <child.component />
              </RouteCustom>
            ));
          }
          return (
            <RouteCustom
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
              isProtected={route.isProtected}
              role={role}
            >
              <route.component />
            </RouteCustom>
          );
        })}
        {availableRoutes.length > 0 ? <Redirect to={fallbackUri} /> : null}
      </Switch>
      <Suspense fallback={null}>
        <ToastContainer />
        <ReactTooltip />
      </Suspense>
      <ConfirmDialog />
    </Router>
  );
};

export function HeaderandSideNav() {
  const [showNavHeader, setShowNavHeader] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const currentRouteObject = combinedRoutes.find((item) => {
      if (item.path.includes(":")) {
        return location.pathname.startsWith(item.path.split(":")[0]);
      } else {
        return location.pathname === item.path;
      }
    });
    console.log(currentRouteObject);

    if (currentRouteObject?.hideNavigation) {
      setShowNavHeader(false);
    } else {
      setShowNavHeader(true);
      if (currentRouteObject?.name) {
        setHeader(dispatch, {
          title: currentRouteObject.name,
          previousPath: "",
          icon: currentRouteObject.icon,
        });
      }
    }

    //show go back button
    if (currentRouteObject?.showGoBack === true) {
      dispatch({ type: SHOW_GOBACK, payload: { showGoback: true } });
    } else {
      dispatch({ type: SHOW_GOBACK, payload: { showGoback: false } });
    }
    //show search component
    if (currentRouteObject?.showSearch === true) {
      dispatch({ type: SHOW_SEARCH, payload: true });
      dispatch({ type: SET_SEARCH_LABEL, payload: currentRouteObject?.name });
    } else {
      dispatch({ type: SHOW_SEARCH, payload: false });
    }
  }, [location.pathname]);
  return (
    <React.Fragment>
      {showNavHeader && <Header className="header" />}
      {showNavHeader && <SideNav className="side-nav" />}
    </React.Fragment>
  );
}

function appropriateFallbackUri(role, isProfileUpdated, roles) {
  if (!isAuthenticated()) {
    return "/login";
  } else {
    if (role === authRoles.superAdmin) {
      return "";
    } else if (role === authRoles.buAdmin || role === authRoles.buSubAdmin) {
      if (!isProfileUpdated) {
        return "/profile";
      } else {
        return getFallbackUrl(roles);
      }
    } else if (role === authRoles.buInspector) {
      return "/customers";
    } else {
      return "";
    }
  }
}

export default AppRouter;
