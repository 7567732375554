/* eslint-disable react-hooks/exhaustive-deps */
import { faFolder, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "main/components/Custom/CustomInput";
import { showToast } from "main/shared/helpers";
import { fileSizeLimits, folders } from "main/shared/validations";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createDocument } from "services/documents";
import { uploadToS3 } from "services/s3.js";
import { NEW_DOCUMENTS } from "store/actions";

const MAX_FILES = 10;

function AddDocumentModal(props) {
  const [uploadFilesArray, setUploadFilesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const fileRef = React.useRef(null);

  const dispatch = useDispatch();

  const handleAddMore = () => {
    fileRef.current.click();
  };

  const handleChange = (e) => {
    console.log(e.target.files);
    const files = Array.from(e.target.files);
    const uploadFiles = [];
    for (let i = 0; i < files.length; i++) {
      const { type, size, name } = files[i];
      console.log(files[i]);
      if (type === "application/pdf" && size <= fileSizeLimits["5mb"]) {
        uploadFiles.push({ filename: name, file: files[i] });
      }
    }
    if (uploadFiles.length + uploadFilesArray.length > MAX_FILES) {
      showToast("Maximum of 10 files are allowed at a time");
      setUploadFilesArray((s) => [
        ...s,
        ...uploadFiles.slice(0, MAX_FILES - s.length),
      ]);
    } else {
      setUploadFilesArray((s) => [...s, ...uploadFiles]);
    }
  };

  const savedoc = () => {
    setIsLoading(true);
    const promises = uploadFilesArray.map((value) => {
      return new Promise((resolve, reject) => {
        if (value.file) {
          uploadToS3(value.file, folders.documents)
            .then(async (res) => {
              if (res.url) {
                const datas = {
                  type: "laboratory_result",
                  url: res.url,
                  inspection_id: +props.inspect_id,
                  name: value.filename,
                };
                await createDocument(datas);
                resolve("");
              }
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve("");
        }
      });
    });

    Promise.all(promises)
      .then(() => {
        handleClose();
        showToast("Documents uploaded successfully", true);
        dispatch({ type: NEW_DOCUMENTS });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast("Failed to upload some documents");
        console.log(err);
      });
  };

  const handleClose = () => {
    setUploadFilesArray([]);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <h5 style={{ fontWeight: "bolder" }}>Add Documents</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={handleClose}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          {uploadFilesArray.map((arr, index) => (
            <DocumentName
              key={`file-${index}`}
              index={index}
              filename={arr.filename}
              setIsValid={setIsValid}
              deleteFile={() => {
                setUploadFilesArray((s) => {
                  const copy = [...s];
                  copy.splice(index, 1);
                  setIsValid(copy.every((i) => i.filename));
                  return copy;
                });
              }}
              onChangeText={(text) => {
                setUploadFilesArray((state) => {
                  const deepState = [...state];
                  deepState[index].filename = text;
                  return deepState;
                });
              }}
              onChangeFile={(file) => {
                setUploadFilesArray((state) => {
                  const deepState = [...state];
                  deepState[index].file = file;
                  return deepState;
                });
              }}
            />
          ))}

          <Button
            className="mt-4 mx-auto"
            onClick={handleAddMore}
            disabled={uploadFilesArray.length === MAX_FILES || isLoading}
          >
            Select Files <FontAwesomeIcon icon={faFolder} />
          </Button>
          <input
            ref={fileRef}
            type="file"
            accept="application/pdf"
            hidden="hidden"
            multiple={true}
            onChange={handleChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          className="text-secondary500 bg-transparent border-0"
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button disabled={isLoading || !isValid} onClick={() => savedoc()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function DocumentName({
  filename,
  onChangeText,
  onChangeFile,
  deleteFile,
  index,
  setIsValid,
}) {
  const [pdferr, setpdferr] = useState("");
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    if (filename) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [filename]);

  const handleChange = (e) => {
    const file = e.target.files[0];
    const type = file && file.type;
    const size = file && file.size;
    const name = file && file.name;
    console.log(name);
    if (type !== "application/pdf") {
      setpdferr("File should be in pdf format");
    } else if (size > fileSizeLimits["5mb"]) {
      if (type === "application/pdf") {
        setpdferr("File size cannot be larger than 50mb");
      }
    } else {
      setpdferr("");
      onChangeFile(file);
      onChangeText(name);
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="mb-1">
      <div className="d-flex mb-1 align-items-center">
        <label className="mr-2 mb-0 text-light20 font-weight-bold">
          {index + 1}
        </label>
        <CustomInput
          label="Document Name"
          value={filename}
          onChange={(e) => onChangeText(e.target.value)}
          inputProps={{ maxLength: "50" }}
          parentClassName="w-100"
        />
        <input
          ref={hiddenFileInput}
          type="file"
          hidden="hidden"
          onChange={(e) => handleChange(e)}
        />
        <button className="btn" onClick={handleClick}>
          <FontAwesomeIcon color="#83857A" icon={faFolder} />
        </button>
        <button
          className="btn"
          onClick={() => {
            setpdferr("");
            deleteFile();
          }}
        >
          <FontAwesomeIcon color="#83857A" icon={faTrash} />
        </button>
      </div>
      <span id="custom-text">{pdferr && pdferr}</span>
    </div>
  );
}

export default AddDocumentModal;
